<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal_form_tifconciliaciones_export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar listado Tif Conciliaciones
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="tif_turno_id" class="col-md-5">Turno</label>
                    <input
                      type="number"
                      id="tif_turno_id"
                      v-model="filtros.tif_turno_id"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="user_front_id" class="col-md-5"
                      >Usuario Firma Frontera</label
                    >
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="user_front"
                      placeholder="Usuarios"
                      label="name"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.usuarioF"
                      :filterable="true"
                      @input="getSelectUsuarioF()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="user_trans_id" class="col-md-5"
                      >Usuario Firma Transportadora</label
                    >
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="user_trans"
                      placeholder="Usuario"
                      label="name"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.usuarioT"
                      :filterable="true"
                      @input="getSelectUsuarioT()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="estado1" class="col-md-5"> Estado</label>
                    <select
                      id="estado"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.estado"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tif_turno_id1"
                              v-model="form.tif_turno_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tif_turno_id1"
                              >Turno</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="user_front_id"
                              v-model="form.user_front_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="user_front_id"
                              >Usuario Firma Frontera</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="user_trans_id"
                              v-model="form.user_trans_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="user_trans_id"
                              >Usuario Firma Transportadora</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_firma_front"
                              v-model="form.fecha_firma_front"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_firma_front"
                              >Fecha Firma Frontera</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_firma_trans"
                              v-model="form.fecha_firma_trans"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_firma_trans"
                              >Fecha Firma Transportadora</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="km_final"
                              v-model="form.km_final"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="km_final"
                              >Kilometros Finales</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="min_ope"
                              v-model="form.min_ope"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="min_ope"
                              >Minutos Operativos</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="min_disp"
                              v-model="form.min_disp"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="min_disp"
                              >Minutos Disponibles</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="min_indisp"
                              v-model="form.min_indisp"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="min_indisp"
                              >Minutos Indisponibles</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="observacion_front"
                              v-model="form.observacion_front"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="observacion_front"
                              >Observación Frontera</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="observacion_trans"
                              v-model="form.observacion_trans"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="observacion_trans"
                              >Observación Transportadora</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="estado1"
                              v-model="form.estado"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="estado1"
                              >Estado</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="$store.getters.can('tif.conciliaciones.export')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "TifConciliacionesExport",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      user_front: {},
      user_trans: {},
      form: {
        tif_turno_id: true,
        user_front_id: true,
        user_trans_id: true,
        fecha_firma_front: true,
        fecha_firma_trans: true,
        km_final: true,
        min_ope: true,
        min_disp: true,
        min_indisp: true,
        observacion_front: true,
        observacion_trans: true,
        estado: true,
      },
      filtros: {
        tif_turno_id: null,
        user_front_id: null,
        user_trans_id: null,
        estado: null,
      },
      listasForms: {
        usuarioF: [],
        usuarioT: [],
        estados: [],
      },
    };
  },

  methods: {
    async getUsuarioF() {
      await axios.get("/api/admin/usuarios/lista").then((response) => {
        this.listasForms.usuarioF = response.data;
      });
    },

    async getUsuarioT() {
      await axios.get("/api/admin/usuarios/lista").then((response) => {
        this.listasForms.usuarioT = response.data;
      });
    },

    getSelectUsuarioF() {
      this.filtros.user_front = {};
      this.filtros.user_front_id = null;
      if (this.user_front) {
        this.filtros.user_front = this.user_front;
        this.filtros.user_front_id = this.user_front.id;
      }
    },

    getSelectUsuarioT() {
      this.filtros.user_trans = {};
      this.filtros.user_trans_id = null;
      if (this.user_trans) {
        this.filtros.user_trans = this.user_trans;
        this.filtros.user_trans_id = this.user_trans.id;
      }
    },

    getEstados() {
      axios.get("/api/lista/115").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tif/conciliaciones/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getUsuarioF();
    await this.getUsuarioT();
    await this.getEstados();
    this.cargando = false;
  },
};
</script>
